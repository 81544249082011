import { io, Socket } from 'socket.io-client'

const getUrl = () => {
  switch (import.meta.env.VITE_APP_ENV) {
    case 'prod':
      return 'https://socket.io.best-express-it.com.ua'
    case 'prodNbu':
      return 'https://socket.io.best-express-it.com.ua'
    case 'dev':
      return 'https://socket.io.bestleasing.com.ua'
    case 'local':
      return 'http://localhost:4000'
    default:
      return 'http://localhost:4000'
  }
}

const socket: Socket = io(getUrl(), {
  extraHeaders: {
    apikey: 'IrA1VqblYy',
    'x-api-secret': 'G5Euomjeln',
  },
  reconnectionAttempts: 1,
})

const packageSingleChannel = 'credit_report_package_single'
const packageProgressSignatureChannel = 'credit_resultSignature'
const packageProgressNbuChannel = 'credit_resultNbuSend'
const packageProgressStatusChannel = 'credit_resultNbuGetStatus'

export {
  socket,
  packageSingleChannel,
  packageProgressSignatureChannel,
  packageProgressNbuChannel,
  packageProgressStatusChannel,
}
