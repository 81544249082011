import {
  PackageData,
  Loan,
  Liability,
  PersonShort,
  PersonFull,
} from '@/utils/types'
import { cloneDeep, concat, omit } from 'lodash'
import { keepArray } from 'best-modules/utils/helpers'
import { v4 as uuid } from 'uuid'

function removeNullKeys<
  T extends Record<string, any> | Array<Record<string, any>>,
>(obj: T): T {
  if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
    const newObj: T = {} as T

    for (const key in obj) {
      if (Object.hasOwn(obj, key)) {
        const value = obj[key]

        if (typeof value === 'object' && value !== null) {
          let cleanedValue: T[Extract<keyof T, string>] & object
          if ('value' in value || 'f170' in value) {
            cleanedValue = value
          } else {
            cleanedValue = removeNullKeys(value)
          }
          if (Object.keys(cleanedValue).length > 0) {
            newObj[key] = cleanedValue
          }
        }
        if (
          (typeof value !== 'object' &&
            value !== null &&
            value !== '' &&
            value !== undefined) ||
          key === 'sum'
        ) {
          newObj[key] = value
        }
      }
    }

    return newObj
  }

  if (Array.isArray(obj)) {
    return obj.map(removeNullKeys) as T
  }

  return obj
}

const formatK020 = (person: PersonShort[] | PersonFull[] = []): void => {
  person.forEach(p => {
    if (p.k020 && p.k021_reg_code_type) {
      const isResident = ['1', '2', '3', 'M', 'G', 'F'].includes(
        p.k021_reg_code_type
      )

      let k020: string

      if (isResident) {
        k020 = p.k020.padStart(10, '0')
      } else {
        k020 = `I${p.k020.slice(-9).padStart(9, '0')}`
      }
      p.k020 = k020
    }
  })
}
const setPassport = (person: PersonFull[] | PersonShort[]): void => {
  const getEntityKey = (
    p: PersonFull | PersonShort
  ): 'ind_person' | 'non_res_ind_person' | 'ind_person_short' => {
    switch (true) {
      case 'ind_person' in p:
        return 'ind_person'
      case 'non_res_ind_person' in p:
        return 'non_res_ind_person'
      case 'ind_person_short' in p:
        return 'ind_person_short'
    }
  }
  person.forEach(p => {
    const isPerson = ['1', '7'].includes(p.f082_person_type)
    if (!isPerson) {
      return
    }

    const entityKey = getEntityKey(p)
    const entity = p[entityKey]

    if (!entity) {
      console.warn(`setPassport() - filed with key ${entityKey} not found`)
      return
    }
    const isIntPassport = (documentType: string): boolean => {
      return ['6', '7'].includes(documentType)
    }
    const isBookPassport = (documentType: string): boolean => {
      return documentType === '4' // Паспорт громадянина України у формі книжечки
    }
    let passportKey: string
    let unusedPassportKey: string
    if (isIntPassport(entity.h005_document_type)) {
      passportKey = 'int_passport'
      unusedPassportKey = 'passport'
    } else {
      passportKey = 'passport'
      unusedPassportKey = 'int_passport'
    }

    if (
      entity?.document_number &&
      entity?.document_series &&
      (isIntPassport(entity.h005_document_type) ||
        isBookPassport(entity.h005_document_type))
    ) {
      p[entityKey][passportKey] =
        `${entity.document_series}${entity.document_number}`
    } else {
      delete p[entityKey][passportKey]
    }
    delete p[entityKey][unusedPassportKey]
  })
}
const setInCr = (packageData: PackageData): void => {
  packageData.person_full[0].in_cr =
    packageData.person_full[0].f150_event !== '100'
}

const setAccountInfo = (data: Loan[] | Liability[] = []): void => {
  data.forEach(d => {
    if (d.tranche?.length > 0) {
      d.account_info = concat(...d.tranche.map(t => t.account_info))
    }
  })
}
const setF170NonTrancheFields = (
  data: Loan[] | Liability[] = [],
  fieldName: string | string[]
): void => {
  data.forEach(d => {
    if (d.tranche?.length > 0) {
      keepArray(fieldName).forEach(f => {
        d[f] = { f170: '1004' }
      })
    }
  })
}
const deleteNonTrancheFields = (
  data: Loan[] | Liability[] = [],
  fieldName: string | string[]
): void => {
  data.forEach(d => {
    if (d.tranche?.length > 0) {
      keepArray(fieldName).forEach(f => {
        delete d[f]
      })
    }
  })
}

const createAccountInfoId = (
  accountInfo: Array<Record<string, any>> | null | undefined
) => {
  return keepArray(accountInfo).map(a => {
    return { ...a, account_info_id: a.account_info_id || uuid() }
  })
}

const removeAccountInfoId = (
  accountInfo: Array<Record<string, any>> | null | undefined
) => {
  return keepArray(accountInfo).map(a => {
    return omit(a, ['account_info_id'])
  })
}

const mapAccountInfo = (
  packageData: PackageData,
  cb: (
    _account_info: Array<Record<string, any>> | null | undefined
  ) => Array<Record<string, any>> | null | undefined
) => {
  packageData.loan?.forEach(l => {
    l.account_info = cb(l.account_info)
    if (l.tranche?.length) {
      l.tranche.forEach(t => {
        t.account_info = cb(t.account_info)
      })
    }
  })

  packageData.liability?.forEach(l => {
    l.account_info = cb(l.account_info)
    if (l.tranche?.length) {
      l.tranche.forEach(t => {
        t.account_info = cb(t.account_info)
      })
    }
  })

  packageData.collateral?.forEach(l => {
    l?.movable?.forEach(m => {
      m.account_info = cb(m.account_info)
    })
    l?.immovable?.forEach(im => {
      im.account_info = cb(im.account_info)
    })
    l?.deposit?.forEach(d => {
      d.account_info = cb(d.account_info)
    })
  })
}

const formatPackageDataRequest = (packageData: PackageData): PackageData => {
  const data = cloneDeep(packageData)
  formatK020(data.person_full)
  formatK020(data.person_short || [])
  setPassport(data.person_full)
  setPassport(data.person_short || [])
  setInCr(data)
  setAccountInfo(data.loan)
  setF170NonTrancheFields(data.loan, [
    'eff_int_rate',
    'nominal_int_rate',
    'start_nominal_int_rate',
  ])
  deleteNonTrancheFields(data.loan, [
    'annuity',
    'debt_start_date',
    'early_debt_pay',
    'f037_loan_type',
    'f054_interest_frequency',
    'f054_principal_frequency',
    'f131_asset_quality',
    'f134_restruct_tool',
    'grace_period',
    'rev_int_rate',
    's130_fin_instrument',
    's180_start_pay_date',
    'd170_lending_type',
    's262_lending_target',
    'f048_type_int_rate',
    'n048g_ind_var_int_rate',
    'spred',
    'min_var_int_rate',
    'max_var_int_rate',
    'write_off_date',
  ])
  setAccountInfo(data.liability)
  setF170NonTrancheFields(data.liability, 'obligation_end_date')
  deleteNonTrancheFields(data.liability, [
    'f037_loan_type',
    'f131_asset_quality',
    'f134_restruct_tool',
    'revocable',
  ])

  mapAccountInfo(data, removeAccountInfoId)

  return removeNullKeys(data)
}

const formatPackageDataResponse = (packageData: PackageData) => {
  const data = cloneDeep(packageData)

  mapAccountInfo(data, createAccountInfoId)

  return data
}

export { formatPackageDataRequest, formatPackageDataResponse }
