<template>
  <div v-if="status">
    <div class="text-disabled">{{ title }}</div>
    <div class="d-flex align-center gap-3">
      <v-progress-linear
        :striped="status.status === 'inProgress'"
        color="primary"
        height="6"
        :model-value="status.done"
        :max="status.all"></v-progress-linear>
      <div class="text-disabled">{{ `${status.done}/${status.all}` }}</div>
    </div>
    <div v-if="status.error" class="text-error">
      Невідпрацьовані пакети: {{ status.error }}
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { PackageProgressStatus } from '@/utils/types'

export default {
  name: 'PackageProgressStatus',
  props: {
    status: {
      type: Object as PropType<Omit<PackageProgressStatus, 'report'>>,
    },
    title: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss"></style>
