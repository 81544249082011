import { sendRequest } from 'best-modules/utils'
import type { PackageProgress } from '@/utils/types'

const getPackageProgressList = (): Promise<PackageProgress[]> => {
  return sendRequest({
    name: 'packageProgressList',
    url: '/admin/resultProgress',
  })
}

export { getPackageProgressList }
