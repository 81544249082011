<template>
  <v-overlay
    v-model="isOpen"
    :scrim="false"
    persistent
    scroll-strategy="none"
    no-click-animation
    width="400"
    :content-props="{ style: { top: '5rem', right: '1rem' } }">
    <v-card elevation="12">
      <v-expansion-panels v-model="expandedReport" variant="accordion" flat>
        <package-progress-component
          v-for="packageProgress in packageProgressList"
          :key="packageProgress.report.id"
          :package-progress="packageProgress"></package-progress-component>
      </v-expansion-panels>

      <div v-if="isAllPackagesCompleted" class="d-flex justify-end">
        <v-btn variant="plain" @click="isOpen = false">Закрити</v-btn>
      </div>
    </v-card>
  </v-overlay>
</template>

<script lang="ts">
import { computed, ref, watch } from 'vue'
import { PackageProgress, PackageProgressStatus } from '@/utils/types'
import PackageProgressComponent from './PackageProgress.vue'
import { getPackageProgressList } from '@/request/packageProgress'
import {
  socket,
  packageProgressNbuChannel,
  packageProgressSignatureChannel,
  packageProgressStatusChannel,
} from '@/socket'
import { omit } from 'lodash'
import { isPackageCompleted } from './helpers'
import { useUser } from '@/store/user'

export default {
  name: 'PackageProgressDialog',
  components: { PackageProgressComponent },
  setup() {
    const { userData } = useUser()
    const isOpen = ref(false)
    const expandedReport = ref<number>(0)
    const packageProgressList = ref<PackageProgress[]>([])

    watch(packageProgressList.value, list => {
      isOpen.value = !!list.length
    })

    const isAllPackagesCompleted = computed<boolean>(() => {
      return packageProgressList.value.reduce((acc, cur) => {
        return acc && isPackageCompleted(cur)
      }, true)
    })

    const isReportExist = (reportId: string): boolean => {
      return packageProgressList.value.map(p => p.report.id).includes(reportId)
    }

    const handleSocket = (
      statusType: 'signature' | 'nbuSend' | 'nbuGetStatus',
      status: PackageProgressStatus
    ) => {
      if (
        status.report.companyId !== userData.watch_company_user_role.companyId
      ) {
        return
      }

      if (!isReportExist(status.report.id)) {
        packageProgressList.value.push({
          report: status.report,
          signature: null,
          nbuSend: null,
          nbuGetStatus: null,
        })
      }

      const packageProgress = packageProgressList.value.find(
        p => p.report.id === status.report.id
      )
      packageProgress[statusType] = omit(status, ['report'])
    }

    getPackageProgressList().then(res => {
      packageProgressList.value.splice(0)
      packageProgressList.value.push(...res)

      socket.on(packageProgressSignatureChannel, res =>
        handleSocket('signature', res)
      )
      socket.on(packageProgressNbuChannel, res => handleSocket('nbuSend', res))
      socket.on(packageProgressStatusChannel, res =>
        handleSocket('nbuGetStatus', res)
      )
    })

    return {
      isOpen,
      packageProgressList,
      expandedReport,
      isAllPackagesCompleted,
    }
  },
}
</script>

<style scoped lang="scss"></style>
