<template>
  <v-expansion-panel>
    <v-expansion-panel-title>
      <v-icon
        v-if="isComplete"
        v-tooltip="'Готово'"
        icon="mdi-check-circle"
        class="mr-2"
        color="success"></v-icon>
      <v-icon
        v-else
        v-tooltip="'В процесі'"
        icon="mdi-progress-clock"
        class="mr-2"
        color="warning"></v-icon>
      <span>Звіт за {{ period }}</span>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <package-progress-status
        title="Підписання пакетів"
        :status="packageProgress.signature"></package-progress-status>
      <package-progress-status
        title="Відправка пакетів в НБУ"
        :status="packageProgress.nbuSend"></package-progress-status>
      <package-progress-status
        title="Отримання статусу НБУ"
        :status="packageProgress.nbuGetStatus"></package-progress-status>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { PackageProgress } from '@/utils/types'
import PackageProgressStatus from './PackageProgressStatus.vue'
import { isPackageCompleted } from './helpers'

export default {
  name: 'PackageProgressItem',
  components: { PackageProgressStatus },
  props: {
    packageProgress: {
      type: Object as PropType<PackageProgress>,
    },
  },
  setup(props) {
    const [year, month] = props.packageProgress.report.period.split('-')
    const period = `${month}.${year}`

    const isComplete = computed<boolean>(() => {
      return isPackageCompleted(props.packageProgress)
    })

    return { period, isComplete }
  },
}
</script>

<style scoped lang="scss"></style>
