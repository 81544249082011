import { PackageProgress } from '@/utils/types'

const isPackageCompleted = (packageProgress: PackageProgress): boolean => {
  let res: boolean = true
  if (packageProgress.signature?.status === 'inProgress') {
    res = false
  }
  if (packageProgress.nbuSend?.status === 'inProgress') {
    res = false
  }
  if (packageProgress.nbuGetStatus?.status === 'inProgress') {
    res = false
  }

  return res
}

export { isPackageCompleted }
