<template>
  <div>
    <b-input-file
      v-model="localPrivateKey"
      class="mb-5"
      type="base64"
      dropper
      :readonly="readonly"
      :error-messages="errorMessages"
      :multiple="false"
      :accept="['.dat', '.zs2', '.sk', '.jks', '.pk8', '.pfx']"
      label="ЕЦП ключ">
      <template #dropper-label>
        <b>
          Перетягніть сюди файл ключа або
          <u>
            оберіть його <br />
            на своєму носієві
          </u>
        </b>
      </template>
    </b-input-file>
  </div>
</template>

<script lang="ts">
import { BInputFile } from 'best-modules/components'
import { computed, PropType, ref } from 'vue'

export default {
  name: 'SignReader',
  components: { BInputFile },
  emits: ['update:model-value'],
  props: {
    modelValue: {
      type: String as PropType<string>,
    },
    readonly: {
      type: Boolean,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
    },
  },
  setup(props, { emit }) {
    const localPrivateKey = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:model-value', val)
      },
    })

    const isValidSignature = ref(null)

    return {
      isValidSignature,
      localPrivateKey,
    }
  },
}
</script>

<style lang="scss" scoped></style>
