<template>
  <div>
    <div v-if="!showAddress">
      <h4 class="mb-2">{{ title }}:</h4>
      <div class="disabled-address">
        <v-btn
          v-if="userAccessRights.includes('address.create')"
          size="small"
          @click="showAddress = true"
          >Створити</v-btn
        >
      </div>
    </div>
    <div v-if="showAddress" class="address">
      <div class="d-flex align-center justify-space-between">
        <h4 class="mb-2">{{ title }}</h4>
        <v-btn
          v-if="userAccessRights.includes('address.delete')"
          v-tooltip="'Видалити адресу'"
          :loading="$loading.isLoading('deleteCompanyAddress')"
          icon
          size="x-small"
          @click="deleteAddress">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>

      <v-row>
        <v-col cols="12" md="12">
          <b-autocomplete
            v-model="addressObj.countryId"
            :items="[...(directory.K040 || [])].filter(d => !d.closeDate)"
            label="Країна"
            hide-details
            item-value="id"
            placeholder="Оберіть зі списку"
            :error-messages="getErrorMessages(v$.countryId)"
            @blur="v$.countryId.$touch()"></b-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pt-0">
          <b-autocomplete
            v-model="addressObj.regionId"
            :items="[...(directory.KODTER || [])].filter(d => !d.closeDate)"
            label="Регіон"
            hide-details
            item-value="id"
            placeholder="Оберіть зі списку"
            :error-messages="getErrorMessages(v$.regionId)"
            @blur="v$.regionId.$touch()"></b-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <b-input
            v-model="addressObj.district"
            label="Район"
            hide-details
            item-value="id"
            placeholder="Оберіть зі списку"></b-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pt-0">
          <b-input
            v-model="addressObj.zip"
            label="Поштовий індекс"
            hide-details
            placeholder="Введіть текст"
            :error-messages="getErrorMessages(v$.zip)"
            @blur="v$.zip.$touch()"></b-input>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <b-input
            v-model="addressObj.settlementCode"
            label="Код населеного пункту"
            hide-details
            placeholder="Введіть текст"
            :error-messages="getErrorMessages(v$.settlementCode)"
            @blur="v$.settlementCode.$touch()"></b-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pt-0">
          <b-autocomplete
            v-model="addressObj.typeSettlementId"
            label="Категорія населеного пункту"
            hide-details
            :items="[...(directory.FLOC || [])].filter(d => !d.closeDate)"
            placeholder="Оберіть зі списку"
            :error-messages="getErrorMessages(v$.typeSettlementId)"
            @blur="v$.typeSettlementId.$touch()"></b-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <b-input
            v-model="addressObj.locality"
            label="Назва"
            hide-details
            placeholder="Оберіть зі списку"
            :error-messages="getErrorMessages(v$.locality)"
            @blur="v$.locality.$touch()"></b-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pt-0">
          <b-autocomplete
            v-model="addressObj.streetTypeId"
            label="Категорія (тип) вулиці"
            :items="[...(directory.FSTR || [])].filter(d => !d.closeDate)"
            hide-details
            placeholder="Оберіть зі списку"
            :error-messages="getErrorMessages(v$.streetTypeId)"
            @blur="v$.streetTypeId.$touch()"></b-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <b-input
            v-model="addressObj.street"
            label="Назва вулиці"
            hide-details
            placeholder="Введіть текст"
            :error-messages="getErrorMessages(v$.street)"
            @blur="v$.street.$touch()"></b-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pt-0">
          <b-input
            v-model="addressObj.building"
            label="№ будинку"
            hide-details
            placeholder="Введіть текст"
            :error-messages="getErrorMessages(v$.building)"
            @blur="v$.building.$touch()"></b-input>
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <b-input
            v-model="addressObj.corpus"
            label="№ корпусу"
            hide-details
            placeholder="Введіть текст"></b-input>
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <b-input
            v-model="addressObj.facilities"
            label="№ квартири"
            hide-details
            placeholder="Введіть текст"></b-input>
        </v-col>
      </v-row>

      <v-btn
        v-if="
          userAccessRights.includes('address.create') ||
          userAccessRights.includes('address.update')
        "
        class="mt-5"
        :loading="$loading.isLoading(`addressSubmit_${componentId}`)"
        @click="submit">
        Зберегти
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { usePackageDirectory } from '@/store/package-directory'
import { getErrorMessages } from 'best-modules/utils'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {
  createCompanyAddress,
  deleteCompanyAddress,
  updateCompanyAddress,
} from '@/request/company'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { useUser } from '@/store/user'
import { storeToRefs } from 'pinia'
import { CompanyAddress } from '@/utils/types'
import { generateId } from 'best-modules/utils/helpers'

export default {
  props: {
    title: { type: String },
    typeId: { type: Number },
    address: { type: Object },
    companyId: { type: String },
  },
  methods: { getErrorMessages },
  setup(props) {
    const componentId = generateId()

    const { userAccessRights } = storeToRefs(useUser())
    const { directory } = usePackageDirectory()

    const showAddress = ref(false)
    const addressObj = reactive<Partial<CompanyAddress>>({
      countryId: null,
      regionId: null,
      settlementCode: null,
      typeSettlementId: null,
      zip: null,
      locality: null,
      street: null,
      streetTypeId: null,
      district: null,
      building: null,
      corpus: null,
      facilities: null,
      companyId: props.companyId,
      typeId: props.typeId,
    })

    const rules = {
      countryId: { required },
      regionId: { required },
      settlementCode: { required },
      typeSettlementId: { required },
      zip: { required },
      locality: { required },
      street: { required },
      streetTypeId: { required },
      district: {},
      building: { required },
      corpus: {},
      facilities: {},
    }

    const v$ = useVuelidate(rules, addressObj as CompanyAddress)

    const clearAddress = () => {
      for (const key in addressObj) {
        if (!['typeId', 'companyId'].includes(key)) {
          addressObj[key] = null
        }
      }
    }

    watch(
      computed(() => props.address),
      () => {
        if (props.address) {
          Object.assign(addressObj, props.address)
          showAddress.value = true
        } else {
          clearAddress()
        }
      },
      { deep: true, immediate: true }
    )

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return handleAsync(`addressSubmit_${componentId}`, () => {
          if (addressObj.id) {
            return updateCompanyAddress(addressObj as CompanyAddress).then(
              res => {
                Object.assign(addressObj, res)
                setSnackbar({ text: 'Адресу оновлено' })
              }
            )
          } else {
            return createCompanyAddress(addressObj as CompanyAddress).then(
              res => {
                Object.assign(addressObj, res)
                setSnackbar({ text: 'Адресу Створено' })
                return res
              }
            )
          }
        })
      }
    }

    const deleteAddress = async () => {
      if (addressObj.id) {
        await deleteCompanyAddress(addressObj.id)
      }
      setSnackbar({ text: 'Адресу видалено' })
      clearAddress()
      showAddress.value = false
    }

    return {
      userAccessRights,
      showAddress,
      directory,
      addressObj,
      submit,
      v$,
      deleteAddress,
      componentId,
    }
  },
}
</script>

<style scoped lang="scss">
.disabled-address {
  border-radius: 4px;
  border: 1px dashed #514ef3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.address {
  border-radius: 20px;
  border: 1px solid #eaeef4;
  padding: 15px;
}
</style>
